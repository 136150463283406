import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import StringUtils from '../../Utils/stringUtils';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
  @ViewChild('fileInput1') fileInput1: ElementRef;
  @ViewChild('fileInput2') fileInput2: ElementRef;
  selectedFileName: string;
  selectedFileSize: string;
  selectedFilePreview: string;
  @Input() selectedFileType: string;
  @Input() selected: any;
  @Input() isadvancedMulti: boolean = false;
  @Input() selectedFilePreviewMulti: {
    name: string;
    size: string;
    preview: string;
    type: string;
  }[] = [];
  @Input() selectedFiles: File[] = [];
  @Output() fileSelected = new EventEmitter();
  @Input() currentFile: string;
  @Input() multiple: boolean = false;
  @Input() acceptOther: boolean = false;
  constructor(private toastr: ToastrService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      this.selected = changes['selected'].currentValue;
      this.setFile();
    } else if (
      changes['selectedFilePreviewMulti'] &&
      changes['selectedFilePreviewMulti'].currentValue.length != 0
    ) {
      this.selectedFilePreviewMulti =
        changes['selectedFilePreviewMulti'].currentValue;
      this.setFile();
    }
  }

  ngOnInit(): void {
    if (this.selected || this.selectedFiles.length != 0) {
      this.setFile();
    }
  }
  onFileSelected(event) {
    const file = event.target.files[0];
    if (this.validate(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.selectedFileName = file?.name;
        this.selectedFileSize = StringUtils.formatSizeUnits(file.size);
        let fileType = file.type.split('/');
        this.selectedFileType =
          fileType[0] !== 'application' ? fileType[0] : fileType[1];
        this.selectedFilePreview = reader.result as string;

        // Emit the file
        this.fileSelected.emit(file);

        // Reset the file input to allow re-uploading the same file
        event.target.value = '';
      };
    } else {
      this.toastr.error('File size too large');
      event.target.value = ''; // Reset input in case of invalid file
    }
  }

  onFileSelectedMultiple(event) {
    const files = event.target.files;
    console.log(files);

    Object.keys(files).forEach((key) => {
      if (this.validate(files[key])) {
        const reader = new FileReader();
        reader.readAsDataURL(files[key]);
        this.selectedFiles.push(files[key]);
        reader.onload = () => {
          this.selectedFilePreviewMulti.push({
            name: files[key]?.name,
            size: StringUtils.formatSizeUnits(files[key].size),
            preview: reader.result as string,
            type: files[key]?.type.split('/')[0],
          });
        };
      } else {
        this.toastr.error('File size too large');
      }
    });

    this.fileSelected.emit(files);

    // Reset the file input to allow re-uploading the same file
    event.target.value = '';
  }

  onButtonClick() {
    this.multiple
      ? this.fileInput2.nativeElement.click()
      : this.fileInput1.nativeElement.click();
  }

  removeSelectedFile(): void {
    this.selectedFileName = null;
    this.selectedFilePreview = '';
    this.fileInput1.nativeElement.value = '';
    this.fileSelected.emit(null);
  }
  removeSelectedFileMulti(index): void {
    this.selectedFilePreviewMulti.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.fileSelected.emit(this.selectedFiles);
  }

  setFile() {
    if (!this.multiple) {
      this.selectedFilePreview = this.selected;
    } else {
      this.selectedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.selectedFilePreviewMulti.push({
            name: file.name,
            size: StringUtils.formatSizeUnits(file.size),
            preview: reader.result as string,
            type: file.type.split('/')[0],
          });
        };
      });
    }
  }

  isValidFileType(file: File): boolean {
    let allowedTypes = [];
    if (this.currentFile == 'image') {
      allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    }
    if (this.currentFile == 'html') {
      allowedTypes = ['html'];
    }
    return allowedTypes.includes(file.type);
  }

  acceptType() {
    if (this.currentFile == 'image') {
      return 'image/*';
    }
    if (this.currentFile == 'html') {
      return 'text/html';
    }
    if (this.currentFile == 'all') {
      return '*/*';
    }
    return 'image/*';
  }

  getFilePath(type) {
    switch (type) {
      case 'pdf': {
        return 'assets/images/announcement/pdf.png';
      }
      case 'docx': {
        return 'assets/images/announcement/DOC.png';
      }
      case 'xls': {
        return 'assets/images/announcement/exel.png';
      }
      case 'png': {
        return 'assets/images/announcement/IMAge.png';
      }
      case 'jpg': {
        return 'assets/images/announcement/IMAge.png';
      }
      case 'jpeg': {
        return 'assets/images/announcement/IMAge.png';
      }
      case 'mp4': {
        return 'assets/images/announcement/video.png';
      }
      case 'mkv': {
        return 'assets/images/announcement/video.png';
      }
      case 'avi': {
        return 'assets/images/announcement/video.png';
      }
      case 'webm': {
        return 'assets/images/announcement/video.png';
      }
      case 'null': {
        return '';
      }
      default: {
        return 'assets/images/announcement/unknown.png';
      }
    }
  }

  validate(file: File) {
    if (file.size >= 5000000) {
      return false;
    }

    return true;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = document.getElementById('dropzone-default');
    dropzone.classList.add('dragover');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = document.getElementById('dropzone-default');
    dropzone.classList.remove('dragover');
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const dropzone = document.getElementById('dropzone-default');
    dropzone.classList.remove('dragover');

    const files = event.dataTransfer.files;
    if (this.multiple) {
      this.onFileSelectedMultiple({ target: { files } });
    } else {
      this.onFileSelected({ target: { files } });
    }
  }
}
