import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import { ApiResponse, BaseFilter } from 'src/app/core/models/common.models';
import {
  EntityRequest,
  EntityCompany,
  Sites,
} from '../../auth/models/property.models';
import { BaseService } from 'src/app/core/services/base.service';
import { of, tap, catchError, EMPTY, map, shareReplay, Observable } from 'rxjs';
import { SessionUtils } from 'src/app/shared/Utils/sessionUtils';
import {
  Countries,
  CreateSitePayload,
  SiteInfo,
  StateResponse,
  Facility,
  CompanyInfo,
  GetSiteListPayload,
} from '../models/property-management.models';

@Injectable({
  providedIn: 'root',
})
export class PropertyManagementService extends BaseService {
  private inProgressRequests: { [userId: string]: Observable<SiteInfo> } = {};
  constructor(private http: HttpClient) {
    super();
  }

  getEntityInfo(payload: EntityRequest) {
    return this.http.post<ApiResponse<EntityCompany[]>>(
      `${this.gemURL}/company/property/dropdown`,
      payload
    );
  }

  getPropertyInfo(payload: BaseFilter) {
    return this.http.post<ApiResponse<Sites[]>>(
      `${this.gemURL}/internal/property/info`,
      payload
    );
  }

  getSiteList(payload: GetSiteListPayload) {
    return this.http.post<ApiResponse<SiteInfo[]>>(
      `${this.gemURL}/site/list`,
      payload,
      {
        context: new HttpContext().set(BYPASS_INJECTION, true),
      }
    );
  }

  getCompanyInfo(companyId: string) {
    return this.http.get<ApiResponse<CompanyInfo>>(
      `${this.gemURL}/company/${companyId}`
    );
  }

  addNewSite(payload: CreateSitePayload) {
    return this.http.post<ApiResponse<Sites>>(`${this.gemURL}/site`, payload);
  }

  updateSite(payload: CreateSitePayload) {
    return this.http.put<ApiResponse<Sites>>(`${this.gemURL}/site`, payload);
  }

  updateCompany(payload: CreateSitePayload) {
    return this.http.put<ApiResponse<any>>(`${this.gemURL}/company`, payload);
  }

  addNewFacility(payload: CreateSitePayload) {
    return this.http.post<ApiResponse<any>>(`${this.gemURL}/facility`, payload);
  }

  updateFacility(payload: CreateSitePayload) {
    return this.http.put<ApiResponse<Facility>>(
      `${this.gemURL}/facility`,
      payload
    );
  }

  getFacilities(payload: any) {
    return this.http.post<ApiResponse<any>>(
      `${this.gemURL}/facility/list`,
      payload
    );
  }

  getFacilityById(id: string) {
    return this.http.get<ApiResponse<Facility>>(
      `${this.gemURL}/facility/${id}`
    );
  }

  getCountryList() {
    return this.http.get<ApiResponse<Countries[]>>(
      `https://countriesnow.space/api/v0.1/countries/flag/unicode`
    );
  }

  getCountryStates(country: string) {
    return this.http.get<ApiResponse<StateResponse>>(
      `https://countriesnow.space/api/v0.1/countries/states/q?country=${country}`
    );
  }

  getStateCities(country: string, state: string) {
    return this.http.get<ApiResponse<string[]>>(
      `https://countriesnow.space/api/v0.1/countries/state/cities/q?country=${country}&state=${state}`
    );
  }

  uploadPropertyLogo(payload) {
    return this.http.post<ApiResponse<string>>(
      `${this.gemURL}/instio/upload/images`,
      payload
    );
  }

  getSiteInfo(id: string) {
    return this.http.get<ApiResponse<SiteInfo>>(
      `${this.gemURL}/site/${id}/info`
    );
  }

  getSiteInfoCached(id: string) {
    const requestId = `SITE_${id}`;
    if (this.inProgressRequests[requestId]) {
      return this.inProgressRequests[requestId];
    }
    const cachedData = SessionUtils.getFromSessionStorage('SITE', id);
    if (cachedData) {
      return of(cachedData);
    }
    const request = this.http
      .get<ApiResponse<SiteInfo>>(`${this.gemURL}/site/${id}/info`)
      .pipe(
        tap((response) => {
          SessionUtils.setToSessionStorage('SITE', id, response.data);
        }), // tap remains for side effects
        catchError((error) => {
          console.error(`Error fetching SITE details: ${error}`);
          delete this.inProgressRequests[requestId];
          return EMPTY; // Return an empty observable on error
        }),
        map((response) => response.data),
        shareReplay(1)
      );
    this.inProgressRequests[requestId] = request;
    return request;
  }
}
