import { HttpClient, HttpContext, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { CRMActivity, CRMActivityPerformance, CRMPerformance, CRMPerformanceInfo, CRMProfile, CrmPreference, CrmPreferenceRequest, CrmProfileRequest, CrmTagRequest, Tag, WorkOrder } from './models/crm.models';
import { ApiPaginatedResponse, ApiPaginationResponse, ApiResponse, PaginatedWebData } from 'src/app/core/models/common.models';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import { EMPTY, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrmService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  listCRMProfiles(request: CrmProfileRequest) {
    return this.http.post<ApiPaginationResponse<CRMProfile[]>>(`${this.crmURL}crm/v2/profile/list`, request)
  }
  getCrmProfile(crmId: string) {
    return this.http.get<ApiResponse<CRMProfile>>(`${this.crmURL}/crm/v2/profile/${crmId}`)
  }

  getGuestProfile(crmId : string){
    let params = new HttpParams();
    params = params.set('crmId', crmId);
    return this.http.get<ApiResponse<CRMProfile>>(`${this.crmURL}/crm/v2/profile/info`,{params : params})
  }

  getCrmPerformanceInfo(crmId: string) {
    return this.http.get<ApiResponse<CRMPerformanceInfo>>(`${this.crmURL}/crm/v2/performance/${crmId}/info`)
  }

  getCompanySitePerformanceInfo(crmId: string) {
    return this.http.get<ApiResponse<CRMPerformanceInfo>>(`${this.crmURL}/crm/v2/performance/${crmId}`)
  }

  getCrmPreference(crmId: string) {
    return this.http.get<ApiResponse<CrmPreference>>(`${this.crmURL}/crm/v2/preference/${crmId}`)
  }

  getCrmPerformance(crmId: string) {
    return this.http.get<ApiResponse<CRMActivityPerformance>>(`${this.crmURL}/crm/v2/performance/activity/${crmId}`)
  }

  getCrmActivity(crmId: string, page: number, rows: number) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('rows', rows.toString());
    return this.http.get<ApiPaginatedResponse<CRMActivity[]>>(`${this.crmURL}crm/v2/activity/${crmId}`, { params })
  }

  getCrmDocuments(crmId: string) {
    const headers = new HttpHeaders({
      'authKey': environment.authUrl
    });
    return this.http.get<ApiPaginatedResponse<any[]>>(`${this.crmURL}crm/v2/profile/documents?crmId=${crmId}`, { headers })
  }

  addCrmPreference(crmId: string, request: CrmPreferenceRequest) {
    return this.http.post<ApiResponse<CrmPreference>>(`${this.crmURL}crm/v2/preference/${crmId}`, request, { context: new HttpContext().set(BYPASS_INJECTION, true) })
  }

  addCrmTag(crmId: string, request: CrmTagRequest) {
    return this.http.post<ApiResponse<CrmPreference>>(`${this.crmURL}crm/v2/preference/${crmId}/tag`, request, { context: new HttpContext().set(BYPASS_INJECTION, true) })
  }

  getCRMTagsList() {
    return this.http.get<ApiResponse<Tag[]>>(`${this.crmURL}crm/v2/preference/tags/search`)
  }

  deleteCrmTag(crmId: string, tagId: string) {
    return this.http.get<ApiResponse<CrmPreference>>(`${this.crmURL}crm/v2/preference/${crmId}/tag/${tagId}`)
  }

  getCRMWorkOrders(page: number, crmId: string) {
    const params = new HttpParams().set("page", page.toString())
    const headers = new HttpHeaders().append("Customerid", crmId)
    return this.http.get<ApiResponse<WorkOrder>>(`${this.woURL}customer/workorders`, { params: params, headers: headers })
  }

  updateProfile(request: CRMProfile) {
    return this.http.put<ApiResponse<string>>(`${this.crmURL}crm/v2/profile/update`, request)
  }

  getGuestInfo(id: string) {
    let type = 'GUEST'
    const cachedData = this.getFromSessionStorage(type, id);
    if (cachedData) {
      return of(cachedData);
    }
    return this.http.get<ApiResponse<CRMProfile>>(`${this.crmURL}/crm/v2/profile/info?crmId=${id}`).pipe(
      tap((response) => { this.setToSessionStorage(type, id, response.data); }), // tap remains for side effects
      catchError((error) => {
        console.error(`Error fetching ${type} details: ${error}`);
        return EMPTY; // Return an empty observable on error
      }), map((response) => response.data)
    );
  }

  private getKey(type: string, id: string): string {
    return `instio.v3.${type}.${id}`;
  }

  private setToSessionStorage(type: string, id: string, data: any): void {
    const key = this.getKey(type, id);
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  private getFromSessionStorage(type: string, id: string): any | null {
    const key = this.getKey(type, id);
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }


  postActivityNotes(data) {
    return this.http.post<ApiPaginationResponse<any[]>>(`${this.crmURL}crm/v2/activity`, data)
  }
}
